import * as React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import GatsbyImage from '../gatsby-image';
import {
  MasonryCardWrapper,
  PostPreview,
  PostDetails,
  PostTitle,
  PostDate,
  PostExcerpt,
  ReadMore,
} from './masonry-card.style';

interface MasonryCardProps {
  image?: any;
  title: string;
  excerpt: string;
  url: string;
  date?: string;
  tags?: [];
  className?: string;
  readTime?: string;
  callToAction?: string;
  isStatic?: boolean;
  type?: string;
}

const MasonryCard: React.FunctionComponent<MasonryCardProps> = ({
  image,
  title,
  excerpt,
  url,
  date,
  tags,
  className,
  readTime,
  callToAction,
  isStatic,
  type,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['mesonry_card'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
    if (type) {
      addAllClasses.push('masonry_card-' + type);
    }
  }

  let foundImage = "found an image";

  return (
    <MasonryCardWrapper className={addAllClasses.join(' ')} {...props}>
      {image == null ? null : (
        <PostPreview className="post_preview">
          <Link to={url}>
            <GatsbyImage src={image} isStatic={isStatic} alt="post preview" />
            {type && type == 'compact' && (
              <div className='compact-title'>
                <PostTitle className="post_title">{title}</PostTitle>
                <PostDate>{date}</PostDate>
                <ReadMore className='read-more-compact btn-hammer'>
                  <Link to={url}>{callToAction || "Read more"}</Link>
                </ReadMore>
              </div>
            )}
          </Link>
        </PostPreview>
      )}

      {(!type || type && type != 'compact') && (
        <PostDetails className="post_details">
          <PostTitle className="post_title">
            <Link to={url}>{title}</Link>
          </PostTitle>
          <PostExcerpt
            dangerouslySetInnerHTML={{
              __html: excerpt,
            }}
          />
          <ReadMore className='btn-hammer'>
            <Link to={url}>{callToAction || "Read more"}</Link>
          </ReadMore>
        </PostDetails>
      )}
    </MasonryCardWrapper>
  );
};

export default MasonryCard;
